<template>
	<div class="home">
		<nav>
			<div class="wrapper">
				<div class="wrap">
					<div class="logo">
						<img src="@/assets/images/logo_cn.png" v-if="state.language=='zh_CN'">
						<img src="@/assets/images/logo_en.png" v-else>
					</div>
					<ul class="nav">
						<li>{{state.langObj.home}}</li>
						<li>{{state.langObj.about}}</li>
						<li @click="scrollTo">{{state.langObj.download}}</li>
					</ul>
				</div>
				<div class="lang" @click="languageChange()"><img src="@/assets/images/lang.png" width="20"><p>{{state.language=='zh_CN'?'EN':'中文'}}</p></div>
			</div>
		</nav>
		<div class="theme">
			<div class="wrapper">
				<div class="con">
					<img src="@/assets/images/title_okchat.png">
					<p v-html="state.langObj.theme.desc"></p>
					<div class="button-area">
						<div class="android"></div>
						<div class="ios"></div>
					</div>
				</div>
				<div class="pic">
					<img src="@/assets/images/screenshot.png">
				</div>
			</div>
		</div>

		<section class="model1">
			<div class="wrapper">
				<div class="pic">
					<img src="@/assets/images/pic1.png">
				</div>
				
				<div class="con">
					<img src="@/assets/images/title_safe.png" class="til" >
					
					<div class="til-m" v-if="state.language=='zh_CN'">
					</div>
					<div class="til-m title" v-else style="background:none">
						{{ state.langObj.model1.title }}
					</div>
					<p>{{state.langObj.model1.desc}}</p>
					<div class="button-area">
						<div class="android"></div>
						<div class="ios"></div>
					</div>
				</div>
				<div class="path"></div>
			</div>
			
		</section>
		<section class="model2">
			<div class="con">
				<img src="@/assets/images/title_talk.png" class="til" v-if="state.language=='zh_CN'">
				<div class="title" v-else>{{state.langObj.model2.title}}</div>
				<p>{{state.langObj.model2.desc}}</p>
			</div>
			<div class="wrapper">
				
				<ul>
					<li>
						<img src="@/assets/images/icon01.png">
						<h3>{{state.langObj.model2.li01.title}}</h3>
						<p>{{state.langObj.model2.li01.title}}</p>
					</li>
					<li>
						<img src="@/assets/images/icon02.png">
						<h3>{{state.langObj.model2.li02.title}}</h3>
						<p>{{state.langObj.model2.li02.title}}</p>
					</li>
					<li>
						<img src="@/assets/images/icon03.png">
						<h3>{{state.langObj.model2.li03.title}}</h3>
						<p>{{state.langObj.model2.li03.title}}</p>
					</li>
					<li>
						<img src="@/assets/images/icon04.png">
						<h3>{{state.langObj.model2.li04.title}}</h3>
						<p>{{state.langObj.model2.li04.title}}</p>
					</li>
					<li>
						<img src="@/assets/images/icon05.png">
						<h3>{{state.langObj.model2.li05.title}}</h3>
						<p>{{state.langObj.model2.li05.title}}</p>
					</li>
					<li>
						<img src="@/assets/images/icon06.png">
						<h3>{{state.langObj.model2.li06.title}}</h3>
						<p>{{state.langObj.model2.li06.title}}</p>
					</li>
					
				</ul>
			</div>
			
		</section>
		<section class="model3">
			<div class="wrapper">				
				<div class="con">
					<img src="@/assets/images/title_video.png" class="til" v-if="state.language=='zh_CN'">
					<p class="title" v-else v-html="state.langObj.model3.title"></p>
					<p>{{state.langObj.model3.desc}}</p>
					<div class="button-area">
						<div class="android"></div>
						<div class="ios"></div>
					</div>
				</div>
				<div class="pic">
					<img src="@/assets/images/pic2.png">
				</div>
				<div class="path"></div>
			</div>
			
		</section>
		<section class="model4">
			<div class="con">
				<img src="@/assets/images/title_group.png" class="til" v-if="state.language=='zh_CN'">
				<p class="title" v-else>{{state.langObj.model4.title}}</p>
				<p>{{state.langObj.model4.desc}}</p>
			</div>
			<img src="@/assets/images/pic_group.jpg">
		</section>
		<section class="model5" ref="download" id="download">
			<div class="wrapper">
				<div class="con">
					<img src="@/assets/images/title_download.png" class="til" v-if="state.language=='zh_CN'">
					<p class="title" v-else>{{state.langObj.model5.title}}</p>
					<p>{{state.langObj.model5.desc}}</p>
					<div class="button-area">
						<div class="android"></div>
						<div class="ios"></div>
					</div>
				</div>
				<div class="pic">
					<img src="@/assets/images/screenshot.png">
				</div>
			</div>
		</section>
		<footer>
			<div class="wrapper">
				<div class="wrap">
					<div class="logo">
						<img src="@/assets/images/logo_cn.png" v-if="state.language=='zh_CN'">
						<img src="@/assets/images/logo_en.png" v-else>
					</div>
					<p>{{state.langObj.footer.desc}}</p>
					<p>Copyright © 2023 Suixinliao.com. All rights reserved.</p>

					<ul class="nav">
						<li><router-link :to="{name:'privacy'}">{{state.langObj.footer.privacy}}</router-link></li> | 
						<li><router-link :to="{name:'agreement'}">{{state.langObj.footer.clientAgreement}}</router-link></li>
					</ul>
				</div>
				<div class="lang" @click="languageChange()"><img src="@/assets/images/lang.png" width="20"><p>{{state.language=='zh_CN'?'EN':'中文'}}</p></div>
			</div>
		</footer>
	</div>
</template>

<script>
	import '@/assets/css/base.css';
	import '@/utils/rem.js';
	import {langData} from '@/utils/language.js';
	import { useRoute } from 'vue-router';
	import { defineComponent, reactive, computed, ref, toRefs, onMounted } from "vue";
	import { useStore } from "vuex";
	export default {
	name: 'Home',
	setup(){
		const $store = useStore();
		const $route = useRoute();
		const state = reactive({
			language:'en_US',
			langObj:{}
			
		});

		const download = ref(null);
		const scrollTo = ()=>{
			window.scrollTo({
				// y方向坐标800px（代码中不需要写明单位）
				top: download.value.getBoundingClientRect().top,
				// 滚动方式是平滑滚动 默认是auto 即instant 直接跳到目标位置  不常用
				behavior: "smooth",
			});
		}

		const languageInit = ()=>{
			if($route.query.language!=undefined&&$route.query.language!='') state.language = $route.query.language;
			console.log(langData)
			if(state.language!=undefined){
				state.langObj = langData[state.language];
			}
			else {
				state.langObj = langData['en_US'];
			}
		}
		const languageChange = ()=>{
			let targetLang = '';
			if(state.language=='zh_CN'){
				targetLang = 'en_US';
			}else{
				targetLang = 'zh_CN';
			}
			state.langObj = langData[targetLang];
			state.language = targetLang;
			console.log(state.langObj)
		}

		languageInit();

		return {
			...toRefs(state),
			state,
			download,
			scrollTo,
			languageChange
		}
	}

}
</script>
<style lang="scss">
	@import url('@/assets/css/home.css');
	
</style>