import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () =>
      import("../views/pages/Privacy.vue"),
  },
  {
    path: "/agreement",
    name: "agreement",
    component: () =>
      import("../views/pages/Agreement.vue"),
  },
];
const  scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition && to.meta.keepAlive) {
    return savedPosition;
  }
  return { left: 0, top:0 };
}
const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior
});

export default router;
